<template>
  <v-container>
    <h2 class="mt-5 mb-3">Search users</h2>
    <v-row justify="center" align="center" class="ma-0">
      <user-autocomplete label="Type a Discord username eg. jendib"
                         :outlined="false"
                         v-on:input="goToUser"
                         v-model="searchUser"></user-autocomplete>
      <v-btn class="ml-6" color="primary" @click="goToUser">Search</v-btn>
    </v-row>

    <div v-if="user" class="mt-6">
      <v-card class="mb-6">
        <v-card-title>
          {{user.user.account.discordUserName}} ({{user.user.ruleSetType.toLowerCase()}})
          <span class="ml-3 subtitle-1 font-weight-light">{{user.user.discordId}}</span>
          <span class="ml-3 subtitle-1 font-weight-light">{{user.user.id}}</span>
          <v-btn class="ml-3" v-on:click="triggerInventoryChangedEvent()">Trigger inventory changed</v-btn>
        </v-card-title>

        <v-divider class="mx-4"></v-divider>

        <v-card-text>
          <v-chip-group column>
            <v-chip color="yellow" class="black--text">
              Balance {{user.user.balance}}
            </v-chip>
            <v-chip color="brown lighten-2" class="black--text">
              Lore dust {{user.user.loreDust}}
            </v-chip>
            <v-chip color="green" class="black--text">
              Items in inventory {{user.inventoryCount}}
            </v-chip>
            <v-chip color="blue" class="black--text">
              Unique items in inventory {{user.inventoryUniqueCount}}/{{user.itemCount}}
            </v-chip>
            <v-chip v-if="!user.isActive" color="red lighten-2" class="black--text">
              Inactif
            </v-chip>
            <v-chip v-if="user.leaderboard" color="red lighten-2" class="black--text">
              Score {{user.leaderboard.score}}
            </v-chip>
            <v-chip v-if="user.leaderboard" color="orange" class="black--text">
              #{{user.leaderboard.position}}
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </v-card>

      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title>Inventory</v-card-title>

            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-center">Quantity</th>
                  <th class="text-left">Item</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="inventory of inventories" :key="inventory.id">
                  <td class="text-center">{{inventory.quantity}}</td>
                  <td>
                    <span v-for="index in inventory.item.rarity" :key="index">⭐</span> {{inventory.item.name}}<span v-if="inventory.shinyLevel === 1">+</span><span v-if="inventory.shinyLevel === 2">*</span>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card class="mb-6">
            <v-card-title>Trade logs</v-card-title>

            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-left">From</th>
                  <th class="text-left">To</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="log of logs.tradeLogs" :key="log.id">
                  <td>{{log.date}}</td>
                  <td>
                    <div>
                      <router-link :to="{ name: 'users', params: { discordId: log.userFrom.discordUserName }}">
                        {{ log.userFrom.discordUserName }}
                      </router-link>
                    </div>
                    <div v-for="(item, index) of log.itemFrom" :key="log.id + item.id + index">
                      <span v-for="index in item.rarity" :key="index">⭐</span> {{item.name}}
                    </div>
                  </td>
                  <td>
                    <router-link :to="{ name: 'users', params: { discordId: log.userTo.discordUserName }}">{{ log.userTo.discordUserName }}</router-link><br/>
                    <div v-for="(item, index) of log.itemTo" :key="log.id + item.id + index">
                      <span v-for="index in item.rarity" :key="index">⭐</span> {{item.name}}
                    </div>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-card-title>Recycle logs</v-card-title>

            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-center">Lore dust</th>
                  <th class="text-left">Item</th>
                  <th class="text-left"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="log of logs.recycleLogs" :key="log.id">
                  <td>{{log.date}}</td>
                  <td class="text-center">{{log.loreDust}}</td>
                  <td>
                    <span v-for="index in log.item.rarity" :key="index">⭐</span> {{log.item.name}}<span v-if="log.shinyLevel === 1">+</span><span v-if="log.shinyLevel === 2">*</span>
                  </td>
                  <td>
                    <v-btn text v-on:click="deleteRecycleLog(log)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

          <v-card>
            <v-card-title>Inventory logs</v-card-title>

            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-center">Quantity change</th>
                  <th class="text-left">Item</th>
                  <th class="text-left">Source</th>
                  <th class="text-left"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="log of logs.inventoryLogs" :key="log.id">
                  <td>{{log.date}}</td>
                  <td class="text-center">{{log.deltaQuantity}}</td>
                  <td>
                    <span v-for="index in log.item.rarity" :key="index">⭐</span> {{log.item.name}}<span v-if="log.shinyLevel === 1">+</span><span v-if="log.shinyLevel === 2">*</span>
                  </td>
                  <td>{{log.source}}</td>
                  <td>
                    <v-btn text v-on:click="deleteInventoryLog(log)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import UserAutocomplete from "@/components/UserAutocomplete";

export default {
    name: 'Users',
    components: {
      UserAutocomplete
    },

    watch: {
      $route(to) {
        this.discordId = to.params.discordId;
        this.loadUser();
      }
    },

    data() {
      return {
        discordId: this.$route.params.discordId,
        searchUser: null,
        user: null,
        inventories: [],
        logs: {}
      }
    },

    methods: {
      deleteInventoryLog(log) {
        axios.delete('/inventory/log/' + log.id)
          .then(() => {
            this.logs.inventoryLogs.splice(this.logs.inventoryLogs.indexOf(log), 1);
          });
      },

      deleteRecycleLog(log) {
        axios.delete('/recycle/cancel/' + log.id)
          .then(() => {
            this.logs.recycleLogs.splice(this.logs.recycleLogs.indexOf(log), 1);
          });
      },

      goToUser() {
        if (!this.searchUser) {
          return;
        }

        this.$router.push({
          name: 'users', params: {
            discordId: this.searchUser.discordId
          }
        });
      },

      loadUser() {
        if (!this.discordId) {
          return;
        }

        axios.get('/user/' + encodeURIComponent(this.discordId))
          .then(response => {
            this.user = response.data;
            //this.searchUser = this.user.user;

            axios.get('/inventory/' + this.user.user.account.discordId)
              .then(response => {
                this.inventories = response.data
              });

            axios.get('/user/' + this.user.user.account.discordId + '/log')
              .then(response => {
                this.logs = response.data
              });
          });
      },

      triggerInventoryChangedEvent() {
        axios.post('/user/triggerInventoryChangedEvent/' + this.user.user.account.discordId);
      }
    },

    created() {
      this.loadUser();
    }
  }
</script>
